:root{
    --main-color: #ffc500;
}

/* Margin and padding */
.top_60{
    margin-top: 60px !important;
}
.top_30{
    margin-top: 30px !important;
}
.top_10{
    margin-top: 10px !important;
}
.mb_30{
    margin-bottom: 30px !important;
}
.p_30{
    padding: 30px !important;
}
.pb_45{
    padding-bottom: 45px !important;
}
.grid-container{
    column-gap: 25px;
}
  

@media(max-width:991px){
    .top_60{
        margin-top: 30px !important;
    }
}

/* .container_shadow{
    box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.13);
    -webkit-box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.13);
    -moz-box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.13);
} */

.main-content{
    min-height: 100vh;
    border-radius: 6px;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    margin-bottom: 30px;
    padding: 30px;
    scroll-behavior: smooth;
    
}
section{
    min-height: 100vh;
    width: 100%;
}
.header_navlink, .header_link{
    cursor: pointer;
}
.section_title{
    position: relative;
    display: inline-block;
    padding: 0 3px;
}
.section_title .section_title_text{
    font-size: 18px;
    font-weight: 600;
    position: relative;
    margin: 0;
}
.section_title span{
    position: absolute;
    height: 7px;
    width: 100%;
    bottom: 0;
    opacity: 0.4;
    left: 0;
    background-color: var(--main-color);
}
.contact{
    position: relative;
    min-height: 100vh;
    padding: 100px 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #121131;
}
.contact .content{
    max-width: 800px;
    text-align: center;
}

.contact .content p{
    font-weight: 400;
    color: #fff;
}
.container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
.container .contactInfo{
    width: 50%;
    display: flex;
    flex-direction: column;
}
.container .contactInfo .box{
    position: relative;
    padding: 20px 0;
    display: flex;
}
.container .contactInfo .box .icon{
    min-width: 50px;
    height: 50px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 22px;
}
.container .contactInfo .box .text{
    display: flex;
    margin-left: 20px;
    font-size: 16px;
    color: #fff;
    flex-direction: column;
    font-weight: 400;
}
.container .contactInfo .box .text h3{
    font-weight: 500;
    color: #00bcd4;
}
.contactForm{
    width: 50%;
    padding: 40px;
    background: #fff;
    clip-path: polygon(0 0,calc(100% - 90px) 0,100% 90px,100% 100%, 0 100%);
    position: relative;
    border-radius: 20px;
}
.contactForm::after{
    content: '';
    position: absolute;
    display: block;
    width: 90px;
    height: 90px;
    background: #e1e1f2;
    top: 0;
    right: 0;
    border-bottom-left-radius: 40px;
    box-shadow: 7px 7px 7px rgba(0,0,0,0.5);
}

.contactForm h2{
    font-size: 30px;
    color: #333;
    font-weight: 500;
    text-align: center;
}
.contactForm .inputBox{
    position: relative;
    width: 100%;
    margin-top: 10px;
}
.contactForm .inputBox input,
.contactForm .inputBox textarea{
    width: 100%;
    padding: 5px 0;
    font-size: 16px;
    margin: 10px 0;
    border: none;
    border-bottom: 2px solid #333;
    outline: none;
    resize: none;
}
.contactForm .inputBox span{
    position: absolute;
    left: 0;
    padding: 5px 0;
    font-size: 16px;
    margin: 10px 0;
    pointer-events: none;
    transition: 0.5s;
    color: #666;
}
.contactForm .inputBox input:focus~span,
.contactForm .inputBox input:valid~span,
.contactForm .inputBox textarea:focus~span,
.contactForm .inputBox textarea:valid~span{
    color: #e91e63;
    font-size: 12px;
    transform: translateY(-20px);
}
/* .contactForm .inputBox input[type="submit"]{
    width: 100px;
    background: #00bcd4;
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 10px;
    font-size: 18px;
    border-radius: 20px;
    font-weight: 400;
} */
.popup {
    width: 400px;
    background: #fff;
    border-radius: 6px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 0 30px 30px;
    color: #333;
    
}
.popup img{
    width: 100px;
    margin-top: -50px;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}
.popup h2{
    font-size: 38px;
    font-weight: 500;
    margin: 30px 0 10px;
}
.popup button{
    width: 100%;
    margin-top: 50px;
    padding: 10px 0;
    background: #6fd649;
    color: #fff;
    border: 0;
    outline: none;
    font-size: 18px;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 5px 5px rgba(0,0,0,0.2);
}

@media (max-width:895px){
    .contact{
        padding: 50px;
    }
    .container{
        flex-direction: column;
    }
    .container .contactInfo{
        margin-bottom: 40px;
    }
    .container .contactInfo,
    .container .contactForm{
        width: 100%;
    }
}
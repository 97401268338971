:root{
  --bg-color:#081b29;
  /* --main-color:#00abf0; */
  --text-color:#333;
  --second-text-color:#555;
  --white-color:#fff;
  --cover-color:linear-gradient(45deg,#00abf0,#006a9a);
  --pages-color:linear-gradient(90deg,#fff,#ddd);
  --border: 3px solid #00abf0;
  --box-shadow:0 0 .6rem rgba(0,0,0,.2);
}

.skills{
  background-color: #121121;
  position: relative;
  min-height: 100vh;
  padding: 100px 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.sub-title {
  padding-bottom: 50px;
}

.main-text {
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
}

.skill-main{
  width: 100%;
  margin-top: -30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skills-box{
  flex-wrap: wrap;
  gap: 1.5rem;
}

.skills-box .skills-content{
  flex: 1 1 20rem;
  text-align: center;
  margin-bottom: 1rem;
  /* justify-content: center; */
}

.skills-content h3{
  font-size: 2rem;
  line-height: 1;
  text-decoration: underline;
  margin-top: -0.5rem;
  background: linear-gradient(blue, white,red);
  -webkit-background-clip: text;
  color: transparent;
}

.skills-content .content{
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-wrap: wrap;
  gap: 1.2rem;
  padding: 2.3rem;
  width: 100%;
  height: 100%;
  box-shadow: 16px 14px 20px rgba(0,0,0,1);
  border-radius: 10px;
  position: relative;
  margin-bottom: 5rem;
}

.skills-content .content::before{
  content: "";
  position: absolute;
  width: 1000%;
  height: 1000%;
  background-image: conic-gradient(#ff0000 20deg,transparent 250deg);
  animation: rotate 3s linear infinite;
}

@keyframes rotate {
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(-360deg);
  }
}

.skills-content .content::after{
  content: "";
  position: absolute;
  width: 99%;
  height: 98%;
  background: #121121;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-shadow: inset 20px 20px 20px rgba(0,0,0,1);
}

.skills-content .content span{
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 90px; */
  /* height: 90px; */
  font-size: 1rem;
  font-weight: 600;
  /* position: relative; */
  /* transition: .3s ease; */
}

.content .content-item{
  position: relative;
  width: 100px;
  height: 100px;
  background: rgba(0,0,0,0.5);
  border-radius: 50%;
  overflow: hidden;
  z-index: 1;
}

.content .content-item:hover::before{
  content: "";
  position: absolute;
  inset: -10px 30px;
  background: linear-gradient(315deg,#00ccff,#d400d4);
  transition: 0.5s;
  animation: animate 4s linear infinite;
}

.content .content-item:hover::after{
  content: "";
  position: absolute;
  inset: 30px -10px;
  background: linear-gradient(10deg,rgb(228,62,12),green);
  transition: 0.5s;
  animation: animate 4s linear infinite;
}

@keyframes animate{
  0%{
      transform: rotate(0deg);
  }
  100%{
      transform: rotate(360deg);
  }
}

.img{
  position: absolute;
  inset: 5px;
  z-index: 3;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img:after{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(100,100,100,0.5);
  border-radius: 45px 0 0 45px;
}

.skill-icon{
  width: 60px;
  z-index: 2;
}

.content hr{
  color: #00abf0;
  line-height: 2px;
}
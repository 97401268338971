.timeline{
    padding: 0 !important;
}

/* Header Title */
.timeline .timeline_header{
    padding-top: 7px;
}

.timeline .timeline_firstItem{
    min-height: 80px !important;
}

.timeline .MuiTimelineItem-root{
    min-height: 0px;
}

.timeline .MuiTimelineItem-missingOppositeContent:before{
    display: none;
}
.timeline .timeline_dot_header{
    color: black !important;
    background-color: var(--main-color) !important;
    font-size: small !important;
    padding: 12px !important;
    margin: 0;
    
}

/* Remaining Items */
.timeline .timeline_dot{
    color: black !important;
    border-color: var(--main-color) !important;
    padding: 5px !important;
    margin: 0;
}

.timeline .MuiTimelineConnector-root{
    background-color: darkseagreen !important;
}

.timeline .timeline_dot_header > .MuiSvgIcon-root{
    font-size: 25px;
    color: darkslategray;
}

.timeline .separator_padding{
    padding-left: 19px !important;
}

.timeline .timeline_content{
    padding-top: 0px !important;
    margin-bottom: 0px;
}
